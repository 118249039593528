import { Card } from "@mui/material";
import { useLanding } from "src/contexts/LandingContext";

const CustomCard = ({ children, ...props }) => {
    const { isMobile } = useLanding();
    return (
        <Card
            {...props}
            sx={{
                backgroundColor: '#fff',
                borderRadius: '30px 5px 30px 5px',
                boxShadow: isMobile ? '4px 6px 0px #FDD12A' : '6px 8px 0px #FDD12A',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '0 10px',

                ...props.sx,
            }}
        >
            {children}
        </Card>
    );
};
export default CustomCard