import { Box } from '@mui/material';
import DetailServiceTemplate from 'src/pages/landing/components/services/DetailServiceTemplate';

const BrandActivationService = () => {
  return (
    <DetailServiceTemplate
      titleWhite="Brand"
      titleYellow="Activation"
      desc="we design immersive brand experiences that captivate audiences, making your presence unforgettable in both physical and virtual spaces."
      placeholderWA={
        'Hi!👋🏻 Boleh tolong bantu manage *Brand Activation* untuk bisnis saya?'
      }
    >
      <Box display="flex" flexDirection="column" alignItems="center" gap={3}>
        <img
          src="/static/images/services/brand-activation/1.png"
          alt="content-1"
          width={1225}
          height={518}
          style={{
            maxWidth: '1225px',
            maxHeight: '518px',
            width: '100%',
            height: 'auto',
          }}
        />

        <img
          src="/static/images/services/brand-activation/2.png"
          alt="content-2"
          width={1227}
          height={517}
          style={{
            maxWidth: '1227px',
            maxHeight: '517px',
            width: '100%',
            height: 'auto',
          }}
        />
      </Box>
    </DetailServiceTemplate>
  );
};

export default BrandActivationService;
