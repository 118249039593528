import { Box, Container, Grid } from '@mui/material';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import CustomizedButtons from 'src/components/Button/CustomizedButtons';
import CustomSelect from 'src/components/Form/CustomSelect';
import CustomText from 'src/components/Text/CustomText';
import { useLanding } from 'src/contexts/LandingContext';
import { TEAM_DATA } from 'src/data/local/TeamData';
import TeamPersonnelSlider from 'src/pages/landing/components/hipros/TeamPersonelsSlider';
import TeamPersonnel from 'src/pages/landing/components/hipros/TeamPersonnel';

const fadeIn = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 1 } }
};

export default function OurTeams() {
  const { isMobile } = useLanding();
  const [active, setActive] = useState('All');
  const categories = [
    'All',
    'BOD',
    'Digital Specialist',
    'Account Executive',
    'Business Development',
    'KOL & KOC Specialist',
    'Live Streamer',
    'Social Media Specialist',
    'Design Graphic',
    'Content Creator',
    'Front End Developer',
    'Back End Developer',
    'UI UX Designer',
    'Marketplace Specialist'
  ];

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 100);
  }, []);

  return (
    <>
      <Box
        style={{
          background: '#202020',
          paddingTop: isMobile ? 0 : '200px',
          paddingBottom: '100px'
        }}
      >
        <Container
          style={{
            position: 'relative'
          }}
        >
          {/* Heading */}
          <Box textAlign="center" mb={3}>
            <CustomText type="h1">
              OUR <span className="default-text-shadow">TEAM</span>
            </CustomText>
          </Box>

          {/* Wrap the categories in a Grid */}
          {isMobile ? (
            <CustomSelect
              sx={{
                marginBottom: 4
              }}
              value={active}
              options={categories.map((category) => ({
                value: category,
                label: category
              }))}
              onChange={(e: any) => setActive(e.target.value)}
            />
          ) : (
            <Grid
              container
              justifyContent="center"
              sx={{ mb: 4, gap: 2, zIndex: 1, position: 'relative' }}
            >
              {categories.map((category) => (
                <Grid item key={category}>
                  <CustomizedButtons
                    onClick={() => setActive(category)}
                    sx={{
                      ...(active === category
                        ? {}
                        : {
                            border: 'none',
                            color: 'white',
                            background: 'transparent',
                            textShadow: 'none',
                            // boxShadow: 'none',
                            height: '40px'
                          }),
                      transition:
                        'background-color ease 500ms, padding ease 300ms, margin ease 300ms, font-size ease 300ms, box-shadow ease 300ms',

                      '&:hover':
                        active === category
                          ? {
                              padding: '3px 25px',
                              backgroundColor: '#FFD600', // Yellow background color
                              boxShadow: '5px 5px 0px 0px #131313' // Same black shadow on hover
                            }
                          : {
                              padding: '3px 25px',
                              backgroundColor: 'black', // Same yellow background on hover
                              boxShadow: '5px 5px 0px 0px #131313' // Same black shadow on hover
                            }
                    }}
                  >
                    {category}
                  </CustomizedButtons>
                </Grid>
              ))}
            </Grid>
          )}

          {active === 'All' ? (
            <motion.div
              key="all"
              initial="hidden"
              animate="visible"
              variants={fadeIn}
              style={{
                zIndex: 1,
                position: 'relative'
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                sx={{
                  gap: '35px'
                }}
              >
                {isMobile ? (
                  <>
                    <TeamPersonnelSlider
                      data={TEAM_DATA.slice(0, 9)}
                      direction="left"
                    />

                    <TeamPersonnelSlider
                      data={TEAM_DATA.slice(9, 17)}
                      direction="right"
                    />

                    <TeamPersonnelSlider
                      data={TEAM_DATA.slice(17, 26)}
                      direction="left"
                    />

                    <TeamPersonnelSlider
                      data={TEAM_DATA.slice(26, 34)}
                      direction="right"
                    />
                  </>
                ) : (
                  <>
                    <TeamPersonnelSlider
                      data={TEAM_DATA.slice(0, 12)}
                      direction="left"
                    />

                    <TeamPersonnelSlider
                      data={TEAM_DATA.slice(12, 23)}
                      direction="right"
                    />

                    <TeamPersonnelSlider
                      data={TEAM_DATA.slice(23, 34)}
                      direction="left"
                    />
                  </>
                )}
              </Box>
            </motion.div>
          ) : (
            <motion.div
              key={active}
              initial="hidden"
              animate="visible"
              variants={fadeIn}
              style={{
                zIndex: 1,
                position: 'relative'
              }}
            >
              <Grid container justifyContent="center" spacing={3}>
                {TEAM_DATA.filter((val) => val.role === active).map(
                  (personnel, idx) => (
                    <Grid item xs={isMobile ? 6 : undefined} key={idx}>
                      <TeamPersonnel {...personnel} size="small" />
                    </Grid>
                  )
                )}
              </Grid>
            </motion.div>
          )}

          <Box
            position="absolute"
            style={{
              top: 0,
              left: '15%',
              zIndex: 0
            }}
          >
            {!isMobile && (
              <img
                src="/static/images/background/hipros-background-icon-client.png"
                alt=""
                width="800px"
              />
            )}
          </Box>

          <Box
            position="absolute"
            style={{
              bottom: 0,
              left: '-50px',
              zIndex: 0
            }}
          >
            {!isMobile && (
              <img
                src="/static/images/background/hipros-background-plus-icon.png"
                alt=""
                width="78px"
                height="97px"
              />
            )}
          </Box>
        </Container>

        {!isMobile && (
          <img
            src="/static/images/background/hipros-background-icon-top-right.webp"
            alt=""
            width="236px"
            height="242px"
            style={{
              position: 'absolute',
              top: 0,
              right: 0
            }}
          />
        )}
      </Box>
    </>
  );
}
