import React, {
  ChangeEvent,
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react';
import { useNavigate, useParams } from 'react-router';
import { toast, ToastContainer } from 'react-toastify';
import {
  Article,
  ArticleContextProps,
  ArticleQuery
} from 'src/contexts/interface/ArticleInterface';
import { defaultInitialPagination } from 'src/utils/pagination.util';
import { Pagination } from 'src/utils/type/PaginationInterface';
import { createItem, getDetail, getList } from '../api/api';

const ArticleContext = createContext<ArticleContextProps | undefined>(undefined);

export const useArticle = (): ArticleContextProps => {
  const context = useContext(ArticleContext);
  if (!context) {
    throw new Error('useArticle must be used within an ArticleProvider');
  }
  return context;
};

const RESOURCE = 'article';

export const ArticleProvider: React.FC<{ children: ReactNode }> = ({
  children
}) => {
  const [query, setQuery] = useState<ArticleQuery>({
    page: 1,
    limit: 10
  });
  const [articleList, setArticleList] = useState<Article[]>([]);
  const [pagination, setPagination] = useState<Pagination>(
    defaultInitialPagination
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [detail, setDetail] = useState<Article>(null);
  const [search, setSearch] = useState(false);
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const routerPush = (url: string) => navigate(url);

  const fetchArticles = async (q: Partial<ArticleQuery> = {}) => {
    setLoading(true);
    try {
      const response = await getList(RESOURCE, { ...query, ...q });
      const { data, meta } = response;
      setPagination({
        totalPages: meta.pagination.lastPage,
        totalCount: meta.pagination.total,
        limit: meta.pagination.perPage,
        ...meta.pagination,
      });
      setArticleList(data);
    } catch (error) {
      toast.error('Failed to fetch Article');
    } finally {
      setLoading(false);
    }
  };

  const fetchTopArticle = async () => {
    try {
      const response = await getList(RESOURCE + '/best-articles');
      const { data } = response;

      return data.original;
    } catch (error) {
      toast.error('Failed to fetch Article');
    }
  };

  const fetchNewsFeed = async () => {
    try {
      const response = await getList(RESOURCE + '/newsfeed');
      const { data } = response;

      return data.original;
    } catch (error) {
      toast.error('Failed to fetch Article');
    }
  };

  const fetchRecommendation = async () => {
    try {
      const response = await getList(RESOURCE + '/recommendations');
      const { data } = response;

      return data.original;
    } catch (error) {
      toast.error('Failed to fetch Article');
    }
  };

  const getArticleDetail = async (id: string) => {
    setLoading(true);
    try {
      if (id) {
        const response = await getDetail(RESOURCE + '/detail', id);
        const { data } = response;
        setDetail(data.original);
      } else {
        setDetail(null);
      }
    } catch (error) {
      toast.error('Failed to fetch Article');
    } finally {
      setLoading(false);
    }
  };

  const increaseViews = async (id: string) => {
    const viewedArticles = JSON.parse(localStorage.getItem('viewed-articles'));

    // Initialize viewed articles if undefined
    if (viewedArticles == null) localStorage.setItem('viewed-articles', JSON.stringify([]))
    else if (viewedArticles.includes(id)) return false;

    const res = await createItem(`${RESOURCE}/${id}/views`, null);

    if (res.status !== 200) return false;

    localStorage.setItem('viewed-articles', JSON.stringify([
      ...(viewedArticles ?? []),
      id,
    ]));

    return true
  }

  const onPageChange = (e: ChangeEvent<unknown>, newPage: number) =>
    setQuery((prev) => ({ ...prev, page: newPage }));

  const onRowsPerPageChange = (e: ChangeEvent<HTMLInputElement>) =>
    setQuery((prev) => ({ ...prev, limit: parseInt(e.target.value, 10) }));

  const handleSearchChange = ({
    search,
    tag
  }: {
    search?: string;
    tag?: string;
  }) => {
    setSearch((search && search != '') || (tag && tag != ''));
    setQuery((prev) => ({ ...prev, search, tag }));
  };

  useEffect(() => {
    getArticleDetail(id);
  }, [id]);

  useEffect(() => {
    fetchArticles();
  }, [query]);

  return (
    <ArticleContext.Provider
      value={{
        pagination,
        articleList,
        loading,
        query,
        id,
        detail,
        search,
        fetchArticles,
        fetchTopArticle,
        fetchNewsFeed,
        fetchRecommendation,
        getArticleDetail,
        increaseViews,
        onPageChange,
        onRowsPerPageChange,
        routerPush,
        handleSearchChange
      }}
    >
      <ToastContainer />
      {children}
    </ArticleContext.Provider>
  );
};
