import { Grid } from '@mui/material';
import { useLanding } from 'src/contexts/LandingContext';
import DetailServiceTemplate from 'src/pages/landing/components/services/DetailServiceTemplate';

const TVCPhotoProductionService = () => {
  const { isMobile } = useLanding();

  return (
    <DetailServiceTemplate
      titleWhite="TVC & Photo"
      titleYellow="Production"
      desc={
        <>
          elevate your brand with our dynamic <b>TVC and Photo production</b>.
          from captivating commercials to stunning visuals, we bring your vision
          to life with creativity and precision.
        </>
      }
      placeholderWA={
        'Hi!👋🏻 Boleh tolong bantu manage *TVC & Photo Production* untuk bisnis saya?'
      }
    >
      <Grid container spacing={3} justifyContent='center'>
        <Grid item xs={6} md={4}>
          <img
            src="/static/images/services/tvc-photo-production/1.png"
            alt="content-1"
            width={368}
            height={376}
            style={{
              maxWidth: '100%',
              height: isMobile ? '188px' : '100%',
              objectFit: 'contain',
            }}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <img
            src="/static/images/services/tvc-photo-production/2.png"
            alt="content-2"
            width={340}
            height={376}
            style={{
              maxWidth: '100%',
              height: isMobile ? '188px' : '100%',
              objectFit: 'contain',
            }}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <img
            src="/static/images/services/tvc-photo-production/3.png"
            alt="content-3"
            width={340}
            height={376}
            style={{
              maxWidth: '100%',
              height: '100%',
              objectFit: 'contain',
            }}
          />
        </Grid>
      </Grid>
    </DetailServiceTemplate>
  );
};

export default TVCPhotoProductionService;
