import CustomText from 'src/components/Text/CustomText';

const ArticleTag = ({ children, ...props }) => {
  return (
    <CustomText
      {...props}
      type="p"
      sx={{
        padding: '5px 15px',
        borderRadius: '23px',
        background: '#5A5A5A',
        width: 'max-content',

        ...props.sx,
      }}
    >
      {children}
    </CustomText>
  );
};

export default ArticleTag;
