import { Box, Button, CircularProgress, Container } from '@mui/material';
import dayjs from 'dayjs';
import { motion } from 'framer-motion';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import BackButton from 'src/components/Button/BackButton';
import CustomText from 'src/components/Text/CustomText';
import { useArticle } from 'src/contexts/ArticleContext';
import { useLanding } from 'src/contexts/LandingContext';
import BlogTag from 'src/pages/landing/components/hipros/blog/ArticleTag';
import CustomCard from 'src/pages/landing/components/hipros/CustomCard';
import { formatBigNumber } from 'src/utils/number.util';

const fadeIn = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 1 } }
};

const ArticleDetail = () => {
  const { isMobile, setActiveItem } = useLanding();
  const { id } = useParams();
  const navigate = useNavigate();
  const { loading, detail, increaseViews } = useArticle();

  useEffect(() => {
    setActiveItem('article');

    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 100);

    if (id) increaseViews(id);
  }, [id]);

  const onShare = () => {
    navigator.clipboard.writeText(
      window.location.host + '/article/' + (detail?.uid ?? '')
    );

    toast.success('Share Link Copied')
  };

  return (
    <Box
      id={'blog-' + id}
      pt={isMobile ? 0 : 10}
      style={{
        background: 'black',
        position: 'relative'
      }}
    >
      {loading || !detail ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            width: '100vw',
            height: '100vh'
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Container>
          <Box position="relative" sx={{ padding: '50px 20px' }}>
            <Box
              textAlign="center"
              position="relative"
              mt={isMobile ? 3 : 0}
              mb={isMobile ? 5 : 6}
            >
              <BackButton onClick={() => navigate(-1)} />
            </Box>

            <motion.article
              key="detail"
              initial="hidden"
              animate="visible"
              variants={fadeIn}
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap={1}
              >
                {detail.categories && detail.categories.length > 0 && (
                  <Box display="flex" flexWrap="wrap" gap={1}>
                    {detail.categories.map((tag) => (
                      <BlogTag key={tag.uid}>{tag.name}</BlogTag>
                    ))}
                  </Box>
                )}

                <CustomText
                  type="h1"
                  sx={{
                    fontWeight: '600',
                    fontSize: isMobile ? '20px' : '35px',
                    fontFamily: 'Montserrat',
                    lineHeight: 1,
                    textTransform: 'capitalize',
                    textAlign: 'center'
                  }}
                >
                  {detail.title}
                </CustomText>

                <CustomText
                  type="p"
                  sx={{
                    color: 'white',
                    fontSize: '13px'
                  }}
                >
                  {dayjs(detail.created_at).format('MMMM DD, YYYY')}
                </CustomText>

                <Box display="flex" alignItems="center" gap={1}>
                  <CustomText
                    type="p"
                    sx={{
                      height: '26px',
                      padding: '5px 8px',
                      borderRadius: '23px',
                      background: '#383838',
                      width: 'max-content',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '2px',
                      fontSize: '13px'
                    }}
                  >
                    <img
                      src="/static/images/icons/eye.svg"
                      alt="view"
                      width={14}
                      height={14}
                    />
                    {formatBigNumber(detail.views)}
                  </CustomText>

                  <Button
                    sx={{
                      padding: '5px 8px',
                      borderRadius: '23px',
                      background: '#383838',
                      width: '42px',
                      minWidth: '42px',
                      height: '26px',
                      transition: 'all ease 300ms',

                      '&:hover': {
                        background: '#383838',
                        filter: 'brightness(70%)'
                      }
                    }}
                    onClick={onShare}
                  >
                    <img
                      src="/static/images/icons/share.svg"
                      alt="share"
                      width={14}
                      height={14}
                    />
                  </Button>
                </Box>

                <CustomCard
                  sx={{
                    marginTop: '10px',
                    width: '100%',
                    height: isMobile ? '232px' : '427px',
                    maxWidth: '1312px'
                  }}
                >
                  <img
                    src={detail.image}
                    alt="temp"
                    width={'100%'}
                    height={'427px'}
                    style={{
                      objectFit: 'cover'
                    }}
                  />
                </CustomCard>
              </Box>

              <div
                style={{
                  maxWidth: '1042px',
                  margin: '50px auto',
                  textAlign: 'justify',
                  fontSize: '15px',
                  fontFamily: 'Montserrat',
                  color: 'white'
                }}
                dangerouslySetInnerHTML={{
                  __html: detail.content
                }}
              />
            </motion.article>
          </Box>
        </Container>
      )}

      {/* Background Icons */}
      {!isMobile && (
        <Box
          position="absolute"
          display="flex"
          justifyContent="center"
          style={{
            top: '150px',
            left: 0,
            right: 0,
            zIndex: 0
          }}
        >
          <img
            src="/static/images/icons/hipros-zigzag-blue.png"
            alt=""
            width="98px"
            height="54px"
            style={{
              position: 'absolute',
              top: '0',
              left: '400px'
            }}
          />
          <img
            src="/static/images/icons/hipros-zigzag-purple.png"
            alt=""
            width="102px"
            height="66px"
            style={{
              position: 'absolute',
              top: '0',
              right: '400px'
            }}
          />
          <img
            src="/static/images/icons/hipros-thunder.png"
            alt=""
            width="82px"
            height="80px"
            style={{
              position: 'absolute',
              top: '-60px',
              right: '500px'
            }}
          />
        </Box>
      )}

      {!isMobile && (
        <img
          src="/static/images/background/hipros-background-icon-top-right.webp"
          alt=""
          width="236px"
          height="242px"
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            zIndex: 0
          }}
        />
      )}

      {isMobile && (
        <img
          style={{
            position: 'absolute',
            top: 0,
            zIndex: 0
          }}
          src="/static/images/background/bg-article-icons.png"
          alt="background-icons-mobile"
        />
      )}
    </Box>
  );
};

export default ArticleDetail;
