import { Box, Grid } from '@mui/material';
import DetailServiceTemplate from 'src/pages/landing/components/services/DetailServiceTemplate';

const AppsDevelopmentService = () => {
  return (
    <DetailServiceTemplate
      titleWhite="Apps"
      titleYellow="Development"
      desc="transform your ideas into powerful apps with our expert app development services. from concept to deployment, we bring innovation and functionality to your fingertips, ensuring a seamless and user-friendly experience."
      placeholderWA={"Hi!👋🏻 Boleh tolong bantu manage *Apps Development* untuk bisnis saya?"}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <img
            src="/static/images/services/apps-development/1.png"
            alt="content-1"
            width={648}
            height={404}
            style={{
              maxWidth: '100%',
              height: 'auto'
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <img
            src="/static/images/services/apps-development/2.png"
            alt="content-2"
            width={639}
            height={393}
            style={{
              maxWidth: '100%',
              height: 'auto'
            }}
          />
        </Grid>
      </Grid>
    </DetailServiceTemplate>
  );
};

export default AppsDevelopmentService;
