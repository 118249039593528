import { Button } from '@mui/material';

const ChevronButton = ({ sx = {}, onClick = () => {}, ...props }) => {
  return (
    <Button
      {...props}
      sx={{
        borderRadius: '50%',
        background: '#FDD12A',
        minWidth: '40px',
        minHeight: '40px',
        width: '40px',
        height: '40px',
        padding: 0,
        color: 'black',

        ...sx
      }}
      onClick={onClick}
    >
      <svg
        width={props.iconWidth ?? "20"}
        height={props.iconHeight ?? "20"}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="iconamoon:arrow-up-2">
          <path
            id="Vector"
            d="M7.85714 17.1428L15 9.99997L7.85714 2.85712"
            stroke="currentColor"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </Button>
  );
};

export default ChevronButton;
