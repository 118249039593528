import { Grid } from '@mui/material';
import DetailServiceTemplate from 'src/pages/landing/components/services/DetailServiceTemplate';

const LiveStreamingService = () => {
  return (
    <DetailServiceTemplate
      titleWhite="Live"
      titleYellow="Streaming"
      desc={
        <>
          Expand your reach with our live streaming expertise! we seamlessly
          broadcast on various platforms such as{' '}
          <b>Shopee, tokopedia, tiktok, instagram, and more,</b> ensuring your
          content reaches diverse audiences effortlessly
        </>
      }
      placeholderWA={
        'Hi!👋🏻 Boleh tolong bantu manage *Live Streaming* untuk bisnis saya?'
      }
    >
      <Grid container spacing={2} justifyContent='center'>
        <Grid item xs={12} display='flex' justifyContent='center'>
          <img
            src="/static/images/services/livestreaming/1.png"
            alt="content-1"
            width={808}
            height={541}
            style={{
              maxWidth: '100%',
              height: 'auto',
              objectFit: 'contain'
            }}
          />
        </Grid>

        <Grid item xs={7} display='flex' justifyContent='center'>
          <img
            src="/static/images/services/livestreaming/2.png"
            alt="content-2"
            width={650}
            height={489}
            style={{
              maxWidth: '100%',
              height: 'auto',
              objectFit: 'contain'
            }}
          />
        </Grid>

        <Grid item xs={5} display='flex' justifyContent='center'>
          <img
            src="/static/images/services/livestreaming/3.png"
            alt="content-3"
            width={297}
            height={489}
            style={{
              maxWidth: '100%',
              height: 'auto',
              objectFit: 'contain'
            }}
          />
        </Grid>
      </Grid>
    </DetailServiceTemplate>
  );
};

export default LiveStreamingService;
