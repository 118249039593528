import { Box, Grid } from '@mui/material';
import { useRef, useState } from 'react';
import ChevronButton from 'src/components/Button/ChevronButton';
import { useLanding } from 'src/contexts/LandingContext';
import DetailServiceTemplate from 'src/pages/landing/components/services/DetailServiceTemplate';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';

const WebDevelopmentService = () => {
  const { isMobile } = useLanding();
  const swiperRef = useRef<SwiperRef>(null);
  const [swipe, setSwipe] = useState<{
    prev: boolean;
    next: boolean;
  }>({ prev: true, next: false });
  const data = [
    '/static/images/services/web-development/web-1.png',
    '/static/images/services/web-development/web-2.png',
    '/static/images/services/web-development/web-3.png'
  ];

  const webIcons = [
    '/static/images/services/web-development/hipros.png',
    '/static/images/services/web-development/kreasi-loka.png',
    '/static/images/services/web-development/witek.png',
    '/static/images/services/web-development/rovelindo.png',
    '/static/images/services/web-development/foza.png',
    '/static/images/services/web-development/vyatta.png'
  ];

  return (
    <DetailServiceTemplate
      titleWhite="Website"
      titleYellow="Development"
      desc={
        <>
          our website development services <b>bring your vision to life.</b>{' '}
          from sleek design to seamless functionality, we ensure your online
          presence stands our with{' '}
          <b>innovation and user friendly experiences.</b>
        </>
      }
      placeholderWA={
        'Hi!👋🏻 Boleh tolong bantu manage *Website Development* untuk bisnis saya?'
      }
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap={4}
        sx={{ width: '100%', maxWidth: '100%' }}
      >
        {isMobile || (
          <Grid container gap={2} justifyContent="center">
            {webIcons.map((web, idx) => (
              <Grid
                key={idx}
                item
                sx={{
                  '&:hover': {
                    transform: 'scale(1.1)',
                    transition: 'transform ease 300ms'
                  }
                }}
              >
                <img src={web} alt={web} width={170} height={170} />
              </Grid>
            ))}
          </Grid>
        )}

        <Swiper
          ref={swiperRef}
          slidesPerView={1}
          style={{
            width: '100%'
          }}
          onSlideChange={(swiper) => {
            setSwipe({
              prev: swiper.isBeginning,
              next: swiper.isEnd
            });
          }}
        >
          {data.map((url, idx) => (
            <SwiperSlide
              key={idx}
              style={{
                maxWidth: '100%',
                width: '912px',
                alignContent: 'center'
              }}
            >
              <img
                src={url}
                width={912}
                height={565}
                style={{
                  margin: '0 auto',
                  display: 'block',
                  maxWidth: '100%',
                  height: 'auto'
                }}
              />
            </SwiperSlide>
          ))}
        </Swiper>

        <Box display="flex" gap={4}>
          <ChevronButton
            sx={{
              transform: 'scaleX(-1)',
              background: swipe.prev ? 'transparent' : '#FDD12A',
              border: swipe.prev ? '2px solid #B6B6B6' : '2px solid #FDD12A',
              color: swipe.prev ? '#B6B6B6' : 'black',
              transition:
                'filter ease 300ms, background ease 300ms, border ease 300ms',

              '&:disabled': {
                color: '#B6B6B6'
              },
              '&:hover': {
                background: '#FDD12A',
                filter: 'brightness(70%)'
              }
            }}
            disabled={swipe.prev}
            onClick={() =>
              swiperRef.current && swiperRef.current.swiper.slidePrev()
            }
          />

          <ChevronButton
            sx={{
              background: swipe.next ? 'transparent' : '#FDD12A',
              border: swipe.next ? '2px solid #B6B6B6' : '2px solid #FDD12A',
              color: swipe.next ? '#B6B6B6' : 'black',
              transition:
                'filter ease 300ms, background ease 300ms, border ease 300ms',

              '&:disabled': {
                color: '#B6B6B6'
              },
              '&:hover': {
                background: '#FDD12A',
                filter: 'brightness(70%)'
              }
            }}
            disabled={swipe.next}
            onClick={() =>
              swiperRef.current && swiperRef.current.swiper.slideNext()
            }
          />
        </Box>
      </Box>
    </DetailServiceTemplate>
  );
};

export default WebDevelopmentService;
