import { Box } from '@mui/material';
import { useEffect, useRef } from 'react';
import { useLanding } from 'src/contexts/LandingContext';
import TeamPersonnel from 'src/pages/landing/components/hipros/TeamPersonnel';

export interface ITeamPersonnel {
  name: string;
  role: string;
  position: string;
  img: {
    url: string;
    width: number | string;
    height: number | string;
  };
  size?: 'small' | 'big';
}

const TeamPersonnelSlider = ({ data, direction = 'left' }) => {
  const { isMobile } = useLanding();
  const scrollRef = useRef<HTMLDivElement>(null);
  const requestRef = useRef(0);

  useEffect(() => {
    const scrollContainer = scrollRef.current;
    let scrollStep = (direction === 'left' ? 1 : 0.5); // Kecepatan scroll

    if (direction === 'right')
      scrollContainer.scrollLeft = scrollContainer.scrollWidth;

    // Fungsi untuk animasi auto-scroll
    const scrollLoop = () => {
      if (scrollContainer) {
        scrollContainer.scrollLeft +=
          scrollStep * (direction === 'left' ? 1 : -1);

        if (
          (direction === 'left' &&
            scrollContainer.scrollLeft + scrollContainer.clientWidth + 1 >=
              scrollContainer.scrollWidth) ||
          (direction === 'right' && scrollContainer.scrollLeft <= 0)
        ) {
          // Jika mencapai akhir, kembali ke awal
          scrollContainer.scrollLeft =
            direction === 'left' ? 0 : scrollContainer.scrollWidth;
        }

        // Meminta frame berikutnya untuk animasi
        requestRef.current = requestAnimationFrame(scrollLoop);
      }
    };

    // Memulai animasi dengan requestAnimationFrame
    requestRef.current = requestAnimationFrame(scrollLoop);

    // Membersihkan requestAnimationFrame ketika komponen unmount
    return () => {
      cancelAnimationFrame(requestRef.current);
    };
  }, []);

  return (
    <Box
      ref={scrollRef} // Set ref untuk container yang di-scroll
      gap={7}
      style={{
        width: '100%',
        display: 'flex',
        overflowX: 'auto', // Memungkinkan horizontal scroll
        whiteSpace: 'nowrap', // Membuat elemen dalam satu baris
        scrollbarWidth: 'none', // Hide scrollbar di Firefox
        msOverflowStyle: 'none' // Hide scrollbar di IE and Edge
      }}
    >
      {direction === 'right' && (
        <div style={{ minWidth: isMobile ? '200px' : '800px', height: '1px' }} />
      )}

      {data.map((value: ITeamPersonnel, index: number) => (
        <TeamPersonnel
          key={index}
          {...value}
          sx={{
            flex: '1 1 0%'
          }}
        />
      ))}

      {direction === 'left' && (
        <div style={{ minWidth: isMobile ? '200px' : '800px', height: '1px' }} />
      )}
    </Box>
  );
};

export default TeamPersonnelSlider;
