import { Box } from '@mui/material';
import { useRef, useState } from 'react';
import ChevronButton from 'src/components/Button/ChevronButton';
import DetailServiceTemplate from 'src/pages/landing/components/services/DetailServiceTemplate';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';

const KOLManagementService = () => {
  const swiperRef = useRef<SwiperRef>(null);
  const [swipe, setSwipe] = useState<{
    prev: boolean;
    next: boolean;
  }>({ prev: true, next: false });
  const data = [
    '/static/images/services/kol-management/1.png',
    '/static/images/services/kol-management/2.png',
    '/static/images/services/kol-management/3.png',
    '/static/images/services/kol-management/4.png',
    '/static/images/services/kol-management/5.png',
  ];

  return (
    <DetailServiceTemplate
      titleWhite="KOL & KOC"
      titleYellow="Management"
      desc="Unlock the power of influence with our KOL Management. we ensure top-tier key opinion leaders (KOLs) to elevate your brand, providing you with the best-in-class influencers for impactful and authentic promotions."
      placeholderWA={"Hi!👋🏻 Boleh tolong bantu manage *KOL & KOC Management* untuk bisnis saya?"}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap={4}
        sx={{ width: '100%', maxWidth: '100%' }}
      >
        <Swiper
          ref={swiperRef}
          slidesPerView={1}
          style={{
            width: '100%'
          }}
          onSlideChange={(swiper) => {
            setSwipe({
              prev: swiper.isBeginning,
              next: swiper.isEnd
            });
          }}
        >
          {data.map((url, idx) => (
            <SwiperSlide
              key={idx}
              style={{ width: '1084px', maxWidth: '100%', alignContent: 'center' }}
            >
              <img
                src={url}
                key={'kol-' + idx}
                width={1084}
                height={463}
                style={{
                  margin: '0 auto',
                  display: 'block',
                  maxWidth: '100%',
                  height: 'auto'
                }}
              />
            </SwiperSlide>
          ))}
        </Swiper>

        <Box display="flex" gap={4}>
          <ChevronButton
            sx={{
              transform: 'scaleX(-1)',
              background: swipe.prev ? 'transparent' : '#FDD12A',
              border: swipe.prev ? '2px solid #B6B6B6' : '2px solid #FDD12A',
              color: swipe.prev ? '#B6B6B6' : 'black',
              transition:
                'filter ease 300ms, background ease 300ms, border ease 300ms',

              '&:disabled': {
                color: '#B6B6B6'
              },
              '&:hover': {
                background: '#FDD12A',
                filter: 'brightness(70%)'
              }
            }}
            disabled={swipe.prev}
            onClick={() =>
              swiperRef.current && swiperRef.current.swiper.slidePrev()
            }
          />

          <ChevronButton
            sx={{
              background: swipe.next ? 'transparent' : '#FDD12A',
              border: swipe.next ? '2px solid #B6B6B6' : '2px solid #FDD12A',
              color: swipe.next ? '#B6B6B6' : 'black',
              transition:
                'filter ease 300ms, background ease 300ms, border ease 300ms',

              '&:disabled': {
                color: '#B6B6B6'
              },
              '&:hover': {
                background: '#FDD12A',
                filter: 'brightness(70%)'
              }
            }}
            disabled={swipe.next}
            onClick={() =>
              swiperRef.current && swiperRef.current.swiper.slideNext()
            }
          />
        </Box>
      </Box>
    </DetailServiceTemplate>
  );
};

export default KOLManagementService;
