export const formatBigNumber = (
  value: number,
  digits: number = 2,
  maxSymbol: 'B' | 'M' | 'K' = 'B'
) => {
  const lookup = [
    ...(maxSymbol === 'B' ? [{ value: 1e9, symbol: 'B' }] : []),
    ...(['B', 'M'].includes(maxSymbol) ? [{ value: 1e6, symbol: 'M' }] : []),
    ...(['K', 'B', 'M'].includes(maxSymbol)
      ? [{ value: 1e3, symbol: 'K' }]
      : []),
    { value: 1, symbol: '' }
  ];
  const regexp = /\.0+$|(?<=\.[0-9]*[1-9])0+$/;
  const item = lookup.find((item) => value >= item.value);

  return item
    ? item.symbol
      ? (value / item.value)
          .toFixed(digits)
          .replace(regexp, '')
          .concat(`${item.symbol}`)
          .replaceAll('.', ',')
      : value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    : '0';
};